window.onload = function() {

  const dropdownBtns = document.querySelectorAll('.dropdown-btn');
  const clearAllBtn = document.querySelectorAll('.clear-all-btn');
  const applyBtns = document.querySelectorAll('.apply-btn');
  const dropdownContentWrappers = document.querySelectorAll('.toplist-filters__dropdown--wrapper');
  const toplistFiltered = document.querySelector('.casino-toplist-filtered');
  const domToplistItems = Array.from(toplistFiltered.querySelectorAll('.casino-toplist__row'));
  const filterChips = document.getElementById('filter-chips');
  const chips = document.querySelectorAll('.toplist-filters__chips--item');
  const clearChipsBtn = document.getElementById('clear-chips-btn');
  let toplistItems = []
  let dropdownHovering = null;
  let gameTypeFiltered = [];
  let paymentOptionsFiltered = [];

  function closeAllDropdown() {
    const dropdowns = document.querySelectorAll('.toplist-filters__dropdown.active');
    if(dropdowns) {
      dropdowns.forEach(dropdown => {
        dropdown.classList.remove('active');
        const dropdownBtn = dropdown.querySelector('dropdown-btn');
        if(dropdownBtn) {
          dropdownBtn.setAttribute('data-active', 'false');
        }
      })
    }
  }
  const autoCloseDropdown = function(event) {
    const btnItem = event.target
    setTimeout(() => {
      if (dropdownHovering !== btnItem.parentElement.id) {
        btnItem.parentElement.classList.remove('active')
        dropdownHovering = null;
      }
    }, 1000);
    const dataActive = btnItem.getAttribute('data-active');
    if (dataActive === 'true') {
      btnItem.removeEventListener('mouseleave',  autoCloseDropdown, false);
      btnItem.setAttribute('data-active', 'false')
    }
  }

  const confirmRadioCheck = function(e) {
    const dropdownParent = e.target.closest('.toplist-filters__dropdown');
    const dropdownBtn = dropdownParent.querySelector('.dropdown-btn');

    if (e.target.checked) {
      // close dropdown
      dropdownParent.classList.remove('active');
      dropdownBtn.setAttribute('data-active', false);

      if(dropdownParent.id === 'dropdown-sort-by') {
        sortToplist(e.target.value)
      }

      //remove mouse leave event listener
      // dropdownBtn.removeEventListener('mouseleave',  autoCloseDropdown, false);
    }

  }

  const confirmCheckboxCheck = function(e) {

    const dropdownParent = e.target.closest('.toplist-filters__dropdown');
    const dropdownBtn = dropdownParent.querySelector('.dropdown-btn');
    const chip = document.querySelector('button[data-filter-value="'+e.target.value+'"].toplist-filters__chips--item');

    if(e.target.name === 'toplist-filter-games') {
      if (e.target.checked) {
        gameTypeFiltered.push(e.target.value)
      } else {
        gameTypeFiltered = gameTypeFiltered.filter(gameTypeItem => gameTypeItem !== e.target.value)
      }

      filterByGameType()
    }

    if(e.target.name === 'toplist-filter-payment-option') {
      if (e.target.checked) {
        paymentOptionsFiltered.push(e.target.value)
      } else {
        paymentOptionsFiltered = paymentOptionsFiltered.filter(gameTypeItem => gameTypeItem !== e.target.value)
      }

      filterByPaymentOption()
    }

    if (e.target.checked ) {
      if(chip) {
        chip.classList.add('active');
      }
    } else {
      if(chip) {
        chip.classList.remove('active');
      }
    }

    if(paymentOptionsFiltered.length || gameTypeFiltered.length) {
      filterChips.classList.add('active')
    }else{
      filterChips.classList.remove('active')
    }
  }

  function initDropdowns() {
    dropdownBtns.forEach((item) => {

      item.setAttribute('data-active', 'false');

      if(item.classList.contains('disabled')) {
        item.classList.remove('disabled');
      }

      item.addEventListener('click', (e) => {

        closeAllDropdown()

        dropdownHovering = null;
        // toggle wrapper dropdown content
        const isActive = e.target.getAttribute('data-active');
        if (isActive === 'false') {
          e.target.parentElement.classList.add('active');
          e.target.setAttribute('data-active', 'true')
        } else {
          e.target.parentElement.classList.remove('active');
          e.target.setAttribute('data-active', 'false')
          dropdownHovering = null;
        }
        // add mouse leave event
        if (window.innerWidth >= 768) {
          e.target.addEventListener('mouseleave',  autoCloseDropdown, false);
        }
      });
    });

    dropdownContentWrappers.forEach((item) => {
      item.addEventListener('mouseenter', (e) => {
        dropdownHovering = e.target.parentElement.id;
      });

      item.addEventListener('mouseleave', (e) => {
        setTimeout(() => {
          e.target.parentElement.classList.remove('active');
          const btnItem = e.target.parentElement.querySelector('.dropdown-btn')
          btnItem.setAttribute('data-active', 'false')
        }, 1000);
      });
    });

    const radioInputs = document.querySelectorAll('input[type=radio].custom-radio-input');
    const radioArray = Array.from(radioInputs);

    radioArray.forEach((radio) => {
      radio.addEventListener('change', confirmRadioCheck);
    });

    const checkboxInputs = document.querySelectorAll('input[type=checkbox].custom-checkbox-input');
    const checkboxArray = Array.from(checkboxInputs);

    checkboxArray.forEach((checkbox) => {
      checkbox.addEventListener('change', confirmCheckboxCheck);
    });
  }

  function getPartnerData() {
    domToplistItems.forEach(item =>  {
      toplistItems.push({
        index: item.getAttribute('data-index'),
        rating: item.getAttribute('data-rating'),
        payout: item.getAttribute('data-payout'),
        payoutSpeed: item.getAttribute('data-payout-speed').replace(' ', ''),
        games: parseInt(item.getAttribute('data-games')),
        offer: item.getAttribute('data-offer'),
        bonusAmount: parseInt(item.getAttribute('data-bonus-amount').replace(',', '')),
        order: item.getAttribute('data-order')
      })
    })

  }

  initDropdowns();
  if(toplistFiltered) {
    getPartnerData()
  }

  function sortToplist(sortValue) {
    switch (sortValue){
      case 'best-payout-speed':
        toplistItems = toplistItems.sort((a, b) =>
          (a?.payoutSpeed > b?.payoutSpeed) ?
            1 :
            ( (b?.payoutSpeed > a?.payoutSpeed) ? -1 : 0 )
        )
        break
      case 'best-win-rate':
        toplistItems = toplistItems.sort((a, b) => {
          if (a.payout < b.payout) return -1 * -1
          if (a.payout > b.payout) return 1 * -1
          return 0
        })
        break
      case 'best-score':
        toplistItems = toplistItems.sort((a, b) => {
          if (a.rating < b.rating) return -1 * -1
          if (a.rating > b.rating) return 1 * -1
          return 0
        })
        break
      case 'best-bonus-offers':
        toplistItems = toplistItems.sort((a, b) =>{
          if (a.bonusAmount < b.bonusAmount) return -1 * -1
          if (a.bonusAmount > b.bonusAmount) return 1 * -1
          return 0
        })
        break
      case 'wider-game-selection':
        toplistItems = toplistItems.sort((a, b) =>{
          if (a.games < b.games) return -1 * -1
          if (a.games > b.games) return 1 * -1
          return 0
        })
        break
      case 'default':
        domToplistItems.forEach(item => {
          item.setAttribute('style', 'order: ' + item.getAttribute('data-index'))
        })
        break
    }

    if(sortValue !== 'default') {
      toplistItems.forEach((item, key) => {
        const toplistItem = toplistFiltered.querySelector('[data-index="' + item.index + '"].casino-toplist__row')
        if(toplistItem) {
          const orderValue = parseInt(key) + 1;
          toplistItem.setAttribute('style', 'order: ' + orderValue)
        }
      })
    }
  }

  function filterByGameType() {
    if(gameTypeFiltered.length) {
      domToplistItems.forEach((item) => {
        let itemGameTypes = item.getAttribute('data-game-selection');
        if(itemGameTypes) {
          itemGameTypes = itemGameTypes.split('/');
        }else{
          itemGameTypes = [];
        }

        let filterPassed = false;

        if(itemGameTypes.length) {
          gameTypeFiltered.forEach(filter => {
            const filterFormated = filter.toLowerCase().replace(' ', '');

            filterPassed = itemGameTypes.find(itemGameType => itemGameType.toLowerCase().replace(' ', '') === filterFormated);
          })
        }

        if (filterPassed) {
          item.classList.remove('hidden-by-filter-game-type')
        }else{
          item.classList.add('hidden-by-filter-game-type')
        }
      })

    } else {
      domToplistItems.forEach((item) => {
        item.classList.remove('hidden-by-filter-game-type')
      })
    }
  }

  function filterByPaymentOption() {

    if(paymentOptionsFiltered.length) {
      domToplistItems.forEach((item) => {
        let paymentOptions = item.getAttribute('data-payment-methods');
        if(paymentOptions) {
          paymentOptions = paymentOptions.split('/');
        }else{
          paymentOptions = [];
        }

        let filterPassed = false;

        paymentOptionsFiltered.forEach(filter => {
          const filterFormated = filter.toLowerCase().replace(' ', '');

          filterPassed = paymentOptions.find(paymentOption => paymentOption.toLowerCase().replace(' ', '') === filterFormated);
        })

        if (filterPassed) {
          item.classList.remove('hidden-by-filter-payment-option')
        }else{
          item.classList.add('hidden-by-filter-payment-option')
        }
      })

    } else {
      domToplistItems.forEach((item) => {
        item.classList.remove('hidden-by-filter-payment-option')
      })
    }
  }

  if(clearAllBtn.length) {
    clearAllBtn.forEach((item) => {

      item.addEventListener('click', (e) => {
        const dropdownParent = e.target.closest('.toplist-filters__dropdown');
        dropdownParent.classList.remove('active');
        dropdownHovering = null;
        const dropdownBtn = dropdownParent.querySelector('.dropdown-btn');
        dropdownBtn.setAttribute('data-active', 'false')

        if(dropdownParent.id === 'dropdown-games') {
          gameTypeFiltered=[];
          const inputs = dropdownParent.querySelectorAll('input[type=checkbox].custom-checkbox-input');
          inputs.forEach(input => {
            input.checked = false;
          })

          domToplistItems.forEach(item => {
            item.classList.remove('hidden-by-filter-game-type')
          })
        }

        if(dropdownParent.id === 'dropdown-payment-options') {
          paymentOptionsFiltered=[];
          const inputs = dropdownParent.querySelectorAll('input[type=checkbox].custom-checkbox-input');
          inputs.forEach(input => {
            input.checked = false;
          })

          domToplistItems.forEach(item => {
            item.classList.remove('hidden-by-filter-payment-option')
          })
        }
      });
    });
  }

  if(applyBtns.length) {
    applyBtns.forEach((item) => {
      item.addEventListener('click', (e) => {
        const dropdownParent = e.target.closest('.toplist-filters__dropdown');
        dropdownParent.classList.remove('active');
        dropdownHovering = null;
        const dropdownBtn = dropdownParent.querySelector('.dropdown-btn');
        dropdownBtn.setAttribute('data-active', 'false')
      });
    });
  }

  const closeDropdownBtns = document.querySelectorAll('.close-dropdown-mobile');
  closeDropdownBtns.forEach((item) => {
    item.addEventListener('click', (e) => {
      const dropdownParent = e.target.closest('.toplist-filters__dropdown');
      dropdownParent.classList.remove('active');
      dropdownHovering = null;
      const dropdownBtn = dropdownParent.querySelector('.dropdown-btn');
      dropdownBtn.setAttribute('data-active', 'false')
    });
  });

  if (chips.length) {
    chips.forEach((item) => {
      item.addEventListener('click', (e) => {
        const filterType = e.target.getAttribute('data-filter-type');
        const filterValue = e.target.getAttribute('data-filter-value');
        const dropdownId = e.target.getAttribute('data-dropdown');
        const dropdown = document.getElementById(dropdownId);

        if(filterType && filterValue && dropdown) {
            e.target.classList.remove('active')
            const checkbox = dropdown.querySelector('input[value="'+filterValue+'"].custom-checkbox-input');

            if(checkbox) {
              checkbox.checked=false;
            }

            if(filterType === 'game-type') {
              gameTypeFiltered = gameTypeFiltered.filter(item => item !== filterValue);
              filterByGameType();
            }

            if(filterType === 'payment-method') {
              paymentOptionsFiltered = paymentOptionsFiltered.filter(item => item !== filterValue);
              filterByPaymentOption();
            }
          }

        if(paymentOptionsFiltered.length || gameTypeFiltered.length) {
          filterChips.classList.add('active')
        }else{
          filterChips.classList.remove('active')
        }
      });
    });
  }

  if(clearChipsBtn) {
    clearChipsBtn.addEventListener('click', () => {

      if (chips.length) {
        chips.forEach((item) => {
          item.classList.remove('active');
        });
      }

      const allCheckboxFilters = document.querySelectorAll('input.custom-checkbox-input[type=checkbox]:checked');
      allCheckboxFilters.forEach(input => {
        input.checked = false;
      })

      gameTypeFiltered = [];
      paymentOptionsFiltered = [];
      filterByGameType();
      filterByPaymentOption();

      filterChips.classList.remove('active');

    })
  }

}
